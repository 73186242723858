import { Dispatch } from 'redux'

import { mountAuthHeaders, interceptor } from '../utils'
import { IError, ISuccess } from '@pismo/sdk-core'
import { ICreditorResult, IPutCreditor, Merchants } from 'src/resources/merchants'
import { IMKTOperationResult, IMKTPutCreditorsOperation, IOptions, Marketplace } from 'src/resources/marketplace'
import { isString } from 'src/utils/strings'
import { IOperationTypes, Transaction } from 'src/resources/transactions'
import { PaymentsConfig } from 'src/resources/paymentsConfig'

export enum MarketplaceConditionsEvents {
  GET_LIST_CREDITORS = 'GET_LIST_CREDITORS_marketplaceConditions',
  GET_LIST_CREDITORS_ERROR = 'GET_LIST_CREDITORS_ERROR_marketplaceConditions',
  SET_LIST_CREDITORS_ERROR = 'SET_LIST_CREDITORS_ERROR_marketplaceConditions',
  GET_LIST_CREDITOR_OPERATORS = 'GET_LIST_CREDITOR_OPERATORS_marketplaceConditions',
  GET_LIST_CREDITOR_ERROR = 'GET_LIST_CREDITOR_ERROR_marketplaceConditions',
  GET_ALL_LIST_CREDITOR_OPERATORS = 'GET_ALL_LIST_CREDITOR_OPERATORS_marketplaceConditions',
  UPDATE_LIST_CREDITOR_OPERATOR = 'UPDATE_LIST_CREDITOR_OPERATOR_marketplaceConditions',
  GET_ALL_LIST_CREDITOR_ERROR = 'GET_ALL_LIST_CREDITOR_ERROR_marketplaceConditions',
  SET_OPERATIONS_TYPE_LOADING = 'SET_OPERATIONS_TYPE_LOADING',
  GET_CREDITOR = 'GET_CREDITOR_marketplaceCondicoes',
  DELETE_RESULT = 'DELETE_RESULT_marketplaceConditions',
  UPDATE_RESULT = 'UPDATE_RESULT_marketplaceConditions',
  SET_OPERATORS_ERROR = 'SET_OPERATORS_ERROR_marketplaceConditions',
  SET_OPERATORS_SUCCESS = 'SET_OPERATORS_SUCCESS_marketplaceConditions',
  GET_MERCHANTS_CREDITORS = 'GET_MERCHANTS_CREDITORS_marketplaceConditions',
  SET_LOADING = 'SET_LOADING',
}

export interface MarketplaceConditionsAction {
  type: MarketplaceConditionsEvents
  payload?: any
}

interface ErrorListCreditors {
  error: boolean
  message: string
}

export interface MarketplaceConditions {
  listCreditorsOperators: IMKTOperationResult | null
  listAllOperationsType: IOperationTypes[] | null
  errorListCreditorOperation: boolean | null
  errorAllOperationType: boolean | null
  deleteResult: boolean | null
  creditor: ICreditorResult | null
  updateResult: boolean | null
  errorSavingOperator: number[] | null
  merchantsCreditorList: any[] | null
  marketplaceCreditorsList: any[] | null
  errorListCreditors: ErrorListCreditors
  successOperator?: boolean
  loading: boolean
  operationsTypesLoading: boolean
}

export const marketplaceConditions: MarketplaceConditions = {
  listCreditorsOperators: null,
  listAllOperationsType: null,
  errorListCreditorOperation: false,
  errorAllOperationType: false,
  deleteResult: null,
  creditor: null,
  updateResult: null,
  errorSavingOperator: null,
  merchantsCreditorList: null,
  marketplaceCreditorsList: null,
  errorListCreditors: { error: false, message: '' },
  successOperator: undefined,
  loading: false,
  operationsTypesLoading: false,
}

export const marketplaceConditionsReducer = (
  state: MarketplaceConditions = marketplaceConditions,
  action: MarketplaceConditionsAction,
): MarketplaceConditions => {
  switch (action.type) {
    case MarketplaceConditionsEvents.GET_LIST_CREDITOR_OPERATORS:
      return { ...state, listCreditorsOperators: action.payload, errorListCreditorOperation: false, loading: false }
    case MarketplaceConditionsEvents.GET_LIST_CREDITOR_ERROR:
      return { ...state, errorListCreditorOperation: action.payload, listCreditorsOperators: null, loading: false }
    case MarketplaceConditionsEvents.GET_ALL_LIST_CREDITOR_OPERATORS:
      return { ...state, listAllOperationsType: action.payload, loading: false, operationsTypesLoading: false }
    case MarketplaceConditionsEvents.UPDATE_LIST_CREDITOR_OPERATOR:
      const newList: any = {
        items: state.listCreditorsOperators?.items.map((item) =>
          item.operation_type_id === action.payload.operation_type_id ? action.payload : item,
        ),
      }
      return { ...state, listCreditorsOperators: newList, loading: false, operationsTypesLoading: false }
    case MarketplaceConditionsEvents.GET_ALL_LIST_CREDITOR_ERROR:
      return { ...state, errorAllOperationType: true, operationsTypesLoading: false }
    case MarketplaceConditionsEvents.DELETE_RESULT:
      return { ...state, deleteResult: action.payload, loading: false }
    case MarketplaceConditionsEvents.GET_CREDITOR:
      return { ...state, loading: false, creditor: action.payload }
    case MarketplaceConditionsEvents.UPDATE_RESULT:
      return { ...state, updateResult: action.payload, loading: false }
    case MarketplaceConditionsEvents.SET_OPERATORS_ERROR:
      return { ...state, errorSavingOperator: action.payload, loading: false }
    case MarketplaceConditionsEvents.SET_LOADING:
      return { ...state, loading: action.payload }
    case MarketplaceConditionsEvents.SET_OPERATIONS_TYPE_LOADING:
      return { ...state, operationsTypesLoading: action.payload }
    case MarketplaceConditionsEvents.GET_MERCHANTS_CREDITORS:
      return { ...state, merchantsCreditorList: action.payload, loading: false }
    case MarketplaceConditionsEvents.GET_LIST_CREDITORS:
      return { ...state, marketplaceCreditorsList: action.payload, loading: false }
    case MarketplaceConditionsEvents.GET_LIST_CREDITORS_ERROR:
      return { ...state, errorListCreditors: action.payload, marketplaceCreditorsList: null, loading: false }
    case MarketplaceConditionsEvents.SET_LIST_CREDITORS_ERROR:
      return { ...state, errorListCreditors: action.payload }
    case MarketplaceConditionsEvents.SET_OPERATORS_SUCCESS:
      return { ...state, successOperator: action.payload, loading: false }
    default:
      return state
  }
}

export interface IMarketplaceConditionsActions {
  getListCreditor: (marketplaceID: number, credentials: any) => (dispatch: Dispatch) => void
  getCreditor: (creditorID: number, credentials: any) => (dispatch: Dispatch) => void
  getListCreditorsOperations: (
    marketplaceID: number,
    creditorID: number,
    credentials: any,
  ) => (dispatch: Dispatch) => void
  clearCreditorsOperationsResult: () => MarketplaceConditionsAction
  clearListAllOperationsType: () => MarketplaceConditionsAction
  getAllOperationsTypes: (credentials: any) => (dispatch: Dispatch) => void
  deleteOperations: (marketplaceID: number, operationID: number, credentials: any) => (dispatch: Dispatch) => void
  clearDeleteResult: () => MarketplaceConditionsAction
  update: (
    creditor: IPutCreditor,
    operations: IMKTPutCreditorsOperation[],
    credentials: any,
  ) => (dispatch: Dispatch) => void
  clearUpdateResult: () => MarketplaceConditionsAction
  closeSnackbar: () => (dispatch: Dispatch) => void
  setListCreditorError: (value: boolean, message?: string) => (dispatch: Dispatch) => void
  setOperations: (
    marketplaceID: number,
    listOperators: number[],
    props: any,
    credentials: any,
  ) => (dispatch: Dispatch) => void
  getMerchantsCreditorList: (credentials: any, marketplaceId: number) => (dispatch: Dispatch) => void
  clearMerchantsCreditorList: () => MarketplaceConditionsAction
}

export const MarketplaceConditionsActions: IMarketplaceConditionsActions = {
  getListCreditor: (marketplaceID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceConditionsEvents.GET_LIST_CREDITORS, payload: null })
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await MarketplaceClient.getMktCreditors(marketplaceID)
      dispatch({ type: MarketplaceConditionsEvents.GET_LIST_CREDITORS, payload: data })
    } catch (err: any) {
      const message: string = isString(err?.response?.data) ? err?.response?.data : err?.response?.data?.message

      dispatch({
        type: MarketplaceConditionsEvents.GET_LIST_CREDITORS_ERROR,
        payload: {
          error: true,
          message: message,
        },
      })
    }
  },
  getListCreditorsOperations:
    (marketplaceID: number, creditorID: number, credentials: any) => async (dispatch: Dispatch) => {
      dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
      const MarketplaceClient = Marketplace({
        headers: mountAuthHeaders(credentials),
      })

      const client = MarketplaceClient.getClient()

      interceptor(client, dispatch)

      const response: IMKTOperationResult[] | IError = await MarketplaceClient.getMktCreditorCreditorsOperations(
        marketplaceID,
        creditorID,
      )

      if ((response as IError).error) {
        dispatch({ type: MarketplaceConditionsEvents.GET_LIST_CREDITOR_ERROR, payload: true })
        return
      }

      dispatch({ type: MarketplaceConditionsEvents.GET_LIST_CREDITOR_OPERATORS, payload: response })
    },
  clearCreditorsOperationsResult: () => ({
    type: MarketplaceConditionsEvents.GET_LIST_CREDITOR_OPERATORS,
    payload: null,
  }),
  clearListAllOperationsType: () => ({
    type: MarketplaceConditionsEvents.GET_ALL_LIST_CREDITOR_OPERATORS,
    payload: null,
  }),
  clearMerchantsCreditorList: () => ({
    type: MarketplaceConditionsEvents.GET_MERCHANTS_CREDITORS,
    payload: null,
  }),
  getCreditor: (creditorID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
    const MerchantsClient = Merchants({
      headers: mountAuthHeaders(credentials),
    })

    const client = MerchantsClient.getClient()

    interceptor(client, dispatch)

    const response: ICreditorResult | IError = await MerchantsClient.getCreditor(creditorID)

    if ((response as IError).error) {
      return
    }
    dispatch({ type: MarketplaceConditionsEvents.GET_CREDITOR, payload: response as ICreditorResult })
  },
  getAllOperationsTypes: (credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
    dispatch({ type: MarketplaceConditionsEvents.SET_OPERATIONS_TYPE_LOADING, payload: true })
    const PaymentsConfigClient = PaymentsConfig({
      headers: mountAuthHeaders(credentials),
    })

    const client = PaymentsConfigClient.getClient()

    interceptor(client, dispatch)

    try {
      const { data } = await PaymentsConfigClient.getListProcessingCodes()
      dispatch({ type: MarketplaceConditionsEvents.GET_ALL_LIST_CREDITOR_OPERATORS, payload: data })
    } catch (err: any) {
      dispatch({ type: MarketplaceConditionsEvents.GET_ALL_LIST_CREDITOR_ERROR, payload: true })
    }
  },

  deleteOperations: (marketplaceID: number, operationID: number, credentials: any) => async (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const response: ISuccess | IError = await MarketplaceClient.deleteMktCreditorsOperation(marketplaceID, operationID)

    if ((response as IError).error) {
      dispatch({ type: MarketplaceConditionsEvents.DELETE_RESULT, payload: false })
      return
    }

    dispatch({ type: MarketplaceConditionsEvents.DELETE_RESULT, payload: true })
  },

  clearDeleteResult: () => ({ type: MarketplaceConditionsEvents.DELETE_RESULT, payload: null }),

  update:
    (creditor: IPutCreditor, operations: IMKTPutCreditorsOperation[], credentials: any) =>
    async (dispatch: Dispatch) => {
      dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
      const MerchantsClient = Merchants({
        headers: mountAuthHeaders(credentials),
      })

      const client = MerchantsClient.getClient()

      interceptor(client, dispatch)

      try {
        await MerchantsClient.updateCreditors(creditor.id as number, creditor)
        dispatch({ type: MarketplaceConditionsEvents.GET_CREDITOR, payload: creditor as ICreditorResult })
      } catch (erro: any) {
        dispatch({ type: MarketplaceConditionsEvents.UPDATE_RESULT, payload: false })
        return
      }

      await Promise.all(
        operations.map(async (operation) => {
          const MarketplaceClient = Marketplace({
            headers: mountAuthHeaders(credentials),
          })

          const client = MarketplaceClient.getClient()

          interceptor(client, dispatch)

          try {
            await MarketplaceClient.updateMktCreditorsOperation(operation)
            dispatch({ type: MarketplaceConditionsEvents.UPDATE_LIST_CREDITOR_OPERATOR, payload: operation })
          } catch (err: any) {
            dispatch({ type: MarketplaceConditionsEvents.UPDATE_RESULT, payload: false })
          }
        }),
      )

      dispatch({ type: MarketplaceConditionsEvents.UPDATE_RESULT, payload: true })
    },

  clearUpdateResult: () => ({ type: MarketplaceConditionsEvents.UPDATE_RESULT, payload: null }),

  closeSnackbar: () => (dispatch: Dispatch) => {
    dispatch({ type: MarketplaceConditionsEvents.SET_OPERATORS_SUCCESS, payload: false })
    dispatch({ type: MarketplaceConditionsEvents.SET_OPERATORS_ERROR, payload: false })
  },

  setOperations:
    (marketplaceID: number, listOperators: number[], props: any, credentials: any) => async (dispatch: Dispatch) => {
      dispatch({ type: MarketplaceConditionsEvents.SET_OPERATORS_ERROR, payload: false })
      dispatch({ type: MarketplaceConditionsEvents.SET_LOADING, payload: true })
      const MarketplaceClient = Marketplace({
        headers: mountAuthHeaders(credentials),
      })

      const client = MarketplaceClient.getClient()

      interceptor(client, dispatch)

      await Promise.all(
        listOperators.map(async (el) => {
          let data = {
            ...props,
            processing_code: el,
          }
          return await MarketplaceClient.setCreditorsOperation(marketplaceID, { ...data })
        }),
      )
        .then(() => dispatch({ type: MarketplaceConditionsEvents.SET_OPERATORS_SUCCESS, payload: true }))
        .catch((error) =>
          dispatch({
            type: MarketplaceConditionsEvents.SET_OPERATORS_ERROR,
            payload: error.response.data.message,
          }),
        )
    },
  setListCreditorError:
    (value: boolean, message: string = '') =>
    (dispatch: Dispatch) => {
      dispatch({ type: MarketplaceConditionsEvents.SET_LIST_CREDITORS_ERROR, payload: { error: value, message } })
    },
  getMerchantsCreditorList: (credentials: any, marketplaceId: number) => async (dispatch: Dispatch) => {
    const MarketplaceClient = Marketplace({
      headers: mountAuthHeaders(credentials),
    })

    const client = MarketplaceClient.getClient()

    interceptor(client, dispatch)

    const response: IOptions[] | IError = await MarketplaceClient.fetchMerchantCreditors(marketplaceId)

    if ((response as IError)?.error) {
      dispatch({ type: MarketplaceConditionsEvents.GET_MERCHANTS_CREDITORS, payload: [] })
      return
    }

    dispatch({ type: MarketplaceConditionsEvents.GET_MERCHANTS_CREDITORS, payload: response })
  },
}
