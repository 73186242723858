import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { t } from '@lingui/macro'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CircularProgress from '@mui/material/CircularProgress'

import { SearchResultsTyped } from '@Components/search'

import { MarketplaceConditionsActions } from '../../../../store'
import { CurrencyInput } from '@Components/currency-input'
import { PercentInput } from '@Components/percentageInput'
import TextField from '@Components/textField'
import MenuItem from '@mui/material/MenuItem'
import { DownloadRemoveDialog } from '@Components/sellers/sellerDocuments/DownloadRemoveDialog'
import { Typography } from '@mui/material'
import { RootState } from '../../../../store/Store'
import AppBar from '@Components/appBar'
import { IMKTCreditorsOperation } from 'src/resources/marketplace'
import { useSnackbar } from 'notistack'
import { StyledDatePicker } from '@Components/dateRangePicker'
import { formatDate } from 'src/utils/date'

const {
  deleteOperations,
  getCreditor,
  getListCreditorsOperations,
  clearCreditorsOperationsResult,
  update,
  clearDeleteResult,
  clearUpdateResult,
} = MarketplaceConditionsActions

const ConditionsEdit = () => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const { listCreditorsOperators, deleteResult, creditor, updateResult, loading } = useSelector(
    (state: RootState) => state.marketplaceConditions,
  )
  const user = useSelector((state: RootState) => state.user)

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { id, idOperation } = useParams<any>()
  const history = useHistory()

  const [openDialog, setOpenDialog] = useState(false)
  const [isRemove, setIsRemove] = useState({
    status: false,
    item: [] as any,
  })

  const [changedItems, setChangedItems] = useState<any>({})
  const [advancement, setAdvancement] = useState<any>({})

  useEffect(() => {
    dispatch(getCreditor(idOperation, user))
  }, [])

  useEffect(() => {
    if (!listCreditorsOperators) {
      dispatch(getListCreditorsOperations(id, idOperation, user))
    }
  }, [listCreditorsOperators])

  useEffect(() => {
    if (updateResult !== null) {
      if (updateResult) {
        enqueueSnackbar(i18n._(t`dataSuccessfullySaved`), { variant: 'success' })
      } else {
        enqueueSnackbar(i18n._(t`genericApiError`), { variant: 'error' })
      }

      dispatch(clearUpdateResult())
    }
  }, [updateResult])

  useEffect(() => {
    if (deleteResult !== null) {
      if (deleteResult) {
        enqueueSnackbar(i18n._(t`itemDeletedSuccessfully`), { variant: 'success' })
      } else {
        enqueueSnackbar(i18n._(t`genericApiError`), { variant: 'error' })
      }

      dispatch(clearDeleteResult())
      dispatch(getListCreditorsOperations(id, idOperation, user))
    }
  }, [deleteResult])

  const closeDialog = () => {
    setOpenDialog(false)
    setIsRemove({
      status: false,
      item: [],
    })
  }

  const removeOperation = (isItemRemove) => {
    isItemRemove &&
      dispatch(
        deleteOperations(
          id,
          isRemove.item.id ? isRemove.item.id : isRemove.item.marketplace_creditors_operations_id,
          user,
        ),
      )
  }

  const delClicked = (item) => () => {
    setIsRemove({
      status: true,
      item: item,
    })
    setOpenDialog(true)
  }

  const changeHandler = (item, prop: string, type: 'boolean' | 'number') => (val) => {
    const itemId = item.id ? item.id : item.marketplace_creditors_operations_id
    if (item[prop] !== val || changedItems[itemId])
      setChangedItems((state) => ({
        ...state,
        [itemId]: {
          item: {
            ...(changedItems[itemId] ? changedItems[itemId].item : item),
            [prop]: type === 'number' ? Number(val) : Boolean(val),
          },
          props: { ...state[itemId]?.props, [prop]: prop },
        },
      }))
  }

  const changeDateFieldHandler = (item, prop: string) => (val) => {
    const itemId = item.id ? item.id : item.marketplace_creditors_operations_id
    if (item[prop] !== val || changedItems[itemId])
      setChangedItems((state) => {
        return {
          ...state,
          [itemId]: {
            item: {
              ...(changedItems[itemId] ? changedItems[itemId].item : item),
              [prop]: val,
            },
            props: { ...state[itemId]?.props, [prop]: prop },
          },
        }
      })
  }

  const advancementHandler = (prop: string) => (val) => {
    setAdvancement((state) => ({ ...state, [prop]: val }))
  }

  const saveHandler = () => {
    const creditorsOperators: IMKTCreditorsOperation[] = listCreditorsOperators ? listCreditorsOperators?.items : []
    const operations = Object.keys(changedItems)
      .map((key) => changedItems[key])
      .filter((op) => {
        return Object.keys(op.props).some((p) => {
          const marketplaceCreditorsOperationsId = op?.item?.marketplace_creditors_operations_id
          return (
            op.item[p] !==
            creditorsOperators?.find(
              (ot) => ot?.marketplace_creditors_operations_id === marketplaceCreditorsOperationsId,
            )?.[p]
          )
        })
      })
      .map((obj) => ({ ...obj.item, marketplace_id: Number(id) }))

    dispatch(update({ ...creditor, ...advancement }, operations, user))
  }

  const backToList = () => {
    dispatch(clearCreditorsOperationsResult())
    history.push(`/marketplace/edit/${id}/conditions/`)
  }

  const gotoInclude = () => {
    history.push(`/marketplace/edit/${id}/conditions/edit/TypeOperations/${idOperation}`)
  }

  const renderGrid = () => {
    return (
      <Fragment>
        <SearchResultsTyped
          header={[
            { title: i18n._(t`Operação`), prop: 'description' },
            { title: i18n._(t`Tipo de operação`), prop: 'operation_type_id' },
            {
              title: i18n._(t`MDR`),
              cellComponent: (item: any) => (
                <Box minWidth='7rem' justifyContent='center' display='flex'>
                  <PercentInput initialValue={item.mdr} onChange={changeHandler(item, 'mdr', 'number')} />
                </Box>
              ),
            },
            {
              title: i18n._(t`Tarifa por transação`),
              cellComponent: (item) => (
                <Box minWidth='7rem' justifyContent='center' display='flex'>
                  <CurrencyInput
                    lang={language}
                    initialValue={item.transaction_fee}
                    onChange={changeHandler(item, 'transaction_fee', 'number')}
                  />
                </Box>
              ),
            },
            {
              title: i18n._(t`Dias de repasse da primeira parcela`),
              cellComponent: (item) => (
                <Repasse
                  initialValue={item.first_installment_days_to_payment}
                  onChange={changeHandler(item, 'first_installment_days_to_payment', 'number')}
                />
              ),
            },
            {
              title: i18n._(t`Dias de repasse (demais parcelas)`),
              cellComponent: (item) => (
                <Box>
                  <Repasse
                    initialValue={item.n_installment_days_to_payment}
                    onChange={changeHandler(item, 'n_installment_days_to_payment', 'number')}
                  />
                </Box>
              ),
            },
            {
              title: i18n._(t`Forma de repasse da transação`),
              cellComponent: (item) => (
                <RepasseType
                  initialValue={Number(item.matched_settlement) as 0 | 1}
                  onChange={changeHandler(item, 'matched_settlement', 'boolean')}
                />
              ),
            },
            {
              title: i18n._(t`Forma de repasse do MDR`),
              cellComponent: (item) => (
                <RepasseType
                  initialValue={Number(item.matched_mdr) as 0 | 1}
                  onChange={changeHandler(item, 'matched_mdr', 'boolean')}
                />
              ),
            },
            {
              title: i18n._(t`Data de início`),
              cellComponent: (item: any) => {
                return (
                  <StartDate initialValue={item.start_date} onChange={changeDateFieldHandler(item, 'start_date')} />
                )
              },
            },
            {
              title: i18n._(t`Deletar`),
              cellComponent: (item) => (
                <Box>
                  <Button
                    disabled={loading}
                    startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
                    variant='contained'
                    color='primary'
                    onClick={delClicked(item)}
                    data-testid='delete-button'
                  >
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              ),
            },
          ]}
          data={{
            items: listCreditorsOperators?.items || [],
            current_page: 1,
            per_page: 1,
            total_items: Number(listCreditorsOperators?.items.length),
            pages: 1,
          }}
          loading={!listCreditorsOperators?.items}
        />
        {creditor && (
          <Box display='flex' width={1}>
            <Box display='flex' width={1}>
              <Box width={1} display='flex' flexDirection='column'>
                <Box display='flex' mt='30px'>
                  <Box mr={2}>
                    <Typography>{i18n._(t`Tarifa de Antecipação`)}</Typography>
                    <Box data-testid='advancement-advancement_fee'>
                      <CurrencyInput
                        initialValue={creditor.advancement_fee || 0}
                        lang={language}
                        onChange={advancementHandler('advancement_fee')}
                      />
                    </Box>
                  </Box>
                  <Box mr={2}>
                    <Typography>{i18n._(t`Taxa de desconto`)}</Typography>
                    <Box data-testid='advancement-discount_rate'>
                      <PercentInput
                        initialValue={creditor.discount_rate}
                        onChange={advancementHandler('discount_rate')}
                      />
                    </Box>
                  </Box>
                  <Box mr={2}>
                    <Typography>{i18n._(t`Porcentagem Máx de antecipação`)}</Typography>
                    <Box data-testid='advancement-maximum_advancement_percentage'>
                      <PercentInput
                        initialValue={creditor.maximum_advancement_percentage}
                        onChange={advancementHandler('maximum_advancement_percentage')}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box display='flex' justifyContent='center' my='25px'>
          <Box mr={2}>
            <Button color='success' variant='contained' onClick={gotoInclude} data-testid='include-button'>
              {i18n._(t`incluir`)}
            </Button>
          </Box>
          <Box>
            <Button
              disabled={loading}
              startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
              color='primary'
              variant='contained'
              onClick={saveHandler}
              data-testid='save-button'
            >
              {i18n._(t`salvar`)}
            </Button>
          </Box>
        </Box>
      </Fragment>
    )
  }

  return (
    <Box height={'100vh'}>
      <AppBar title={i18n._(t`Creditor`)} back={backToList}></AppBar>
      <Box p={'1rem'} height={'90%'} overflow='auto'>
        {renderGrid()}
      </Box>

      <DownloadRemoveDialog open={openDialog} onClose={closeDialog} isRemove={true} onRemove={removeOperation} />
    </Box>
  )
}

interface RepasseProps {
  initialValue: number
  classes?: any
  onChange?: (e: any) => void
}

const Repasse: React.FC<RepasseProps> = ({ initialValue, classes, onChange }: RepasseProps) => {
  const [val, setVal] = useState(initialValue)

  const handleChange = (e) => {
    const val = e.target.value
    setVal(val)

    if (onChange) onChange(val)
  }
  return (
    <Box>
      <TextField value={val} type='number' onChange={handleChange} inputProps={{ min: 0 }} />
    </Box>
  )
}

const StartDate: React.FC<RepasseProps> = ({ initialValue, onChange }: RepasseProps) => {
  const { i18n, language } = useSelector((state: RootState) => state.translation)
  const [value, setVal] = useState<any>(initialValue)

  const handleChange = (val) => {
    const formattedValue = formatDate(val)

    setVal(formattedValue)

    if (onChange) onChange(formattedValue)
  }
  return (
    <Box>
      <StyledDatePicker
        fullWidth
        name='start_date'
        onChange={handleChange}
        value={value}
        language={language}
        customInput={<TextField fullWidth />}
      />
    </Box>
  )
}

interface RepasseTypeProps extends RepasseProps {
  initialValue: 0 | 1
}

const RepasseType: React.FC<RepasseTypeProps> = ({ initialValue, classes, onChange }: RepasseTypeProps) => {
  const { i18n } = useSelector((state: RootState) => state.translation)
  const [val, setVal] = useState(initialValue)

  const handleChange = (e) => {
    const val = e.target.value
    setVal(val)

    if (onChange) onChange(val)
  }
  return (
    <Box minWidth='130px'>
      <TextField select value={val} onChange={handleChange}>
        <MenuItem key={0} value={0}>
          {i18n._(t`Integral`)}
        </MenuItem>
        <MenuItem key={1} value={1}>
          {i18n._(t`Parcelado`)}
        </MenuItem>
      </TextField>
    </Box>
  )
}

export { ConditionsEdit }
