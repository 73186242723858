import { createClient, ClientInterface, getError } from '@pismo/sdk-core'
import { IError, IGet, ISuccess } from './interfaces'

export interface marketplaceInterface {
  baseURL: string
  headers: {
    Authorization: string
    'x-tenant': string
    'x-protocol'?: string
  }
}

export interface IMarketplaceConfig {
  config: string
  value: string
}

export interface IMarketplace {
  id: number
  document_number: string
  registration: string
  brand_name: string
  marketplace_name: string
  address: string
  number: string
  complementary_address: string
  neighborhood: string
  zip_code: string
  city: string
  state: string
  country: string
  contact: string
  email: string
  phone_number: string
  additional_phone_number: string
  acquirer_mdr: number
  incremental_mdr: number
  days_to_payment: number
  bank_id: number
  bank_agency: string
  bank_agency_vd: string
  bank_account: string
  bank_account_vd: string
  cedente_id: number
  financial_email: string
  chargeback_management: string
  org_as_sub_acquirer: number
  marketplace_configs: IMarketplaceConfig[]
}

export interface IMarketplaceSearchResult {
  id: number
  name: string
  brand_name: string
  document_number: string
}

export interface IResult {
  current_page: number
  pages: number
  per_page: number
  total_items: number
}

export interface ISearchResult extends IResult {
  items: IMarketplaceSearchResult[]
}

export interface ISearchProps {
  page?: number
  per_page?: number
  search?: string
}

export interface ResultDetailsTransaction {
  acquirer: string
  authorization_id: string | number
  cancelled: string
  card: {
    number: number | string
  }
  creation: string
  gateway: string
  gateway_auth_id: string | number
  gateway_message: string
  gateway_order_id: string
  gateway_processor_reference_number: string | number
  gateway_processor_transaction_id: string | number
  gateway_transaction: number
  id: string
  installments: number
  merchant_transactions: [
    {
      discount: number
      event_date: string
      gross_value: number
      id: number
      installment: number
      meta: [
        {
          name: string
          value: string
        },
        {
          name: string
          value: string
        },
      ]
      net_value: number
      number_of_installments: number
      operation: string
      operation_type: number
      payment_date: string
      marketplaceId: number
      receipt_date: string
      received_value: number
      status: string
      type: number
    },
  ]
  network: string
  nsu: string
  operation: string
  order_id: string | number
  paid: number
  payment_type: string
  status: string
  type: string
  updated: string
  value: number
}

export interface IGetDetailsTransactionResult {
  items: ResultDetailsTransaction[]
}

export interface IMarketplaceDetailsGet {
  merchantId: number
  marketplaceAuthorization: string
  marketplaceTransaction: string
}

export interface ICreditorsOperation {
  card_not_present_allowed: boolean
  creditor_id: string
  creditor_name: string
  doc_number_allowed: boolean
  first_installment_days_to_payment: number
  id: number
  installments: any
  marketplace_creditors_operations_id: number
  matched_mdr: true
  matched_settlement: boolean
  mdr: number
  n_installment_days_to_payment: number
  network: any
  operation_type_description: string
  operation_type_id: number
  processing_code: string
  transaction_fee: number
  work_days: boolean
}

export interface IPutMarketplace {
  id: number
  document_number: number
  registration: number
  registration2: number
  brand_name: string
  marketplace_name: string
  address: string
  number: number
  complementary_address: string | number
  neighborhood: string
  zip_code: number
  city: string
  state: string
  country: string
  contact: string
  email: string
  phone_number: number
  additional_phone_number: number
  financial_email: string
  marketplace_configs: [
    {
      config: string
      value: number | string
    },
  ]
}

export interface IPostMarketplace {
  document_number: number
  marketplace_name: string
  brand_name: string
  registration: number
  contact: number
  email: string
  zip_code: number
  address: string
  state: string
  city: string
  country: string
  number: number
  neighborhood: string
  phone_number: number
  marketplace_configs: [
    {
      config: string | number
      value: string | number
    },
  ]
}
export interface ILinkedItem {
  document_number: string
  merchant_id: number
  merchant_name: string
}

export interface IMKTCreditorsOperation {
  card_not_present_allowed?: boolean
  creditor_id: number
  creditor_name: string
  doc_number_allowed: boolean
  first_installment_days_to_payment: number
  id: Number
  installments: number
  marketplace_creditors_operations_id: number
  matched_mdr: boolean
  matched_settlement: boolean
  mdr: number
  n_installment_days_to_payment: number
  network: any
  operation_type_description: string
  operation_type_id: number
  processing_code: number
  transaction_fee: number
  work_days: boolean
  start_date?: string
}

export interface IPostCreditorsOperation {
  creditor_id: number
  transaction_fee: number
  mdr: number
  first_installment_days_to_payment: number
  n_installment_days_to_payment: number
  matched_settlement: boolean
  matched_mdr: boolean
  card_not_present_allowed: boolean
  doc_number_allowed: boolean
  operation_type_id: number
  marketplace_id: number
}

export interface IMKTPutCreditorsOperation extends IMKTCreditorsOperation {
  marketplace_id: number
}

export interface IMKTOperationResult extends IMKTCreditorsOperation {
  items: IMKTCreditorsOperation[]
}

export interface IOptions {
  id: number
  name: string
}

export interface IMKTCreditors {
  creditor_id: number
  creditor_name: string
  marketplace_id: number
  org_id: string
}

export interface IMKTCreditorsResult {
  items: IMKTCreditors[]
}

export interface IMarketplace {
  brand_name: string
  document_number: string
  id: number
  name: string
}

export interface IMarketplaceResult extends IResult {
  items: IMarketplace[]
}

export const Marketplace = ({ headers }) => {
  const baseURL = String(process.env.REACT_APP_API_ADDRESS)
  const client = createClient({ baseURL: `${baseURL}/marketplace`, headers })

  return {
    getClient(): ClientInterface {
      return client
    },

    async search(props) {
      const { search = '', page = 1, per_page = 15, order = 'dsc' } = props ?? {}

      try {
        const { data } = await client.get(
          `/v1/marketplaces?page=${page}&per_page=${per_page}&search=${search}&order=${order}`,
        )

        return data as ISearchResult
      } catch (err) {
        return err
      }
    },

    getMarketplace(marketplaceId: number): Promise<IMarketplace | IError> {
      return client
        .get(`/v1/marketplaces/${marketplaceId}`)
        .then(({ data }) => data as IMarketplace)
        .catch((err) => {
          let message = err.response?.message

          if (!message) {
            return { error: { message: 'Fail on server response' } }
          }

          return { error: { message } }
        })
    },

    async getMarketplaceDetails(props?: IMarketplaceDetailsGet): Promise<any> {
      const { marketplaceAuthorization, marketplaceTransaction, merchantId } = props ?? {}

      try {
        const { data } = (await client.get(
          `/v2/transactions/detail/${marketplaceAuthorization}/${marketplaceTransaction}?merchant=${merchantId}`,
        )) as any

        return data as IGetDetailsTransactionResult
      } catch (err) {
        return err
      }
    },

    async getCreditorsOperation(marketplaceID: number, merchantID: number): Promise<ICreditorsOperation[] | IError> {
      try {
        const { data } = (await client.get(
          `/marketplaces/${marketplaceID}/merchants/${merchantID}/creditors-operations`,
        )) as any

        return data as ICreditorsOperation[]
      } catch (err) {
        return getError(err)
      }
    },

    async updateMarketplace(marketplaceID: number, data: IPutMarketplace): Promise<ISuccess | IError> {
      return await client.put(`/v1/marketplaces/${marketplaceID}`, data)
    },

    async setMarketplace(data: IPostMarketplace) {
      return await client.post(`/v1/marketplaces`, data)
    },

    async getLinkedList(marketplaceID: number): Promise<ILinkedItem[] | IError> {
      try {
        const { data } = (await client.get(`/marketplaces/v1/${marketplaceID}/merchants`)) as any

        return data.merchants as ILinkedItem[]
      } catch (err) {
        return getError(err)
      }
    },

    async linkMerchant(marketplaceID: number, merchantID: number): Promise<ISuccess | IError> {
      try {
        await client.post(`/marketplaces/v1/${marketplaceID}/merchants/${merchantID}`, {
          id: marketplaceID,
          merchantID,
        })

        return { success: true } as ISuccess
      } catch (err) {
        return getError(err)
      }
    },

    async unlinkMerchant(marketplaceID: number, merchantID: number): Promise<ISuccess | IError> {
      try {
        await client.delete(`/marketplaces/v1/${marketplaceID}/merchants/${merchantID}`)

        return { success: true } as ISuccess
      } catch (err) {
        return getError(err)
      }
    },

    async getMktCreditorsOperation(marketplaceID: number): Promise<IMKTOperationResult[] | IError> {
      try {
        const { data } = (await client.get(`/marketplaces/${marketplaceID}/creditors-operations`)) as any

        return data as IMKTOperationResult[]
      } catch (err) {
        return getError(err)
      }
    },

    async getMktCreditors(marketplaceID: number) {
      return await client.get(`/v1/marketplaces/${marketplaceID}/creditors`)
    },

    async getMktCreditorCreditorsOperations(
      marketplaceID: number,
      creditorID: number,
    ): Promise<IMKTOperationResult[] | IError> {
      try {
        const { data } = (await client.get(
          `/v1/marketplaces/${marketplaceID}/creditors/${creditorID}/creditors-operations`,
        )) as any

        return data as IMKTOperationResult[]
      } catch (err) {
        return getError(err)
      }
    },

    async getMarketplaces(merchantID: number, props?: Omit<IGet, 'name'>) {
      const { page = 1, perPage = 9999 } = props ?? {}

      return await client.get(`/v1/marketplaces?merchant=${merchantID}&page=${page}&perPage=${perPage}`)
    },

    async setCreditorsOperation(
      marketplaceID: number,
      dataOperation: IPostCreditorsOperation,
    ): Promise<ISuccess | IError> {
      return await client.post(`/marketplaces/${marketplaceID}/creditors-operations`, dataOperation)
    },

    async updateMktCreditorsOperation(operation: IMKTPutCreditorsOperation) {
      return await client.put(
        `/marketplaces/${operation.marketplace_id}/creditors-operations/${
          operation.id ? operation.id : operation.marketplace_creditors_operations_id
        }`,
        operation,
      )
    },

    async deleteMktCreditorsOperation(marketplaceID: number, operationID: number): Promise<ISuccess | IError> {
      try {
        await client.delete(`/marketplaces/${marketplaceID}/creditors-operations/${operationID}`)

        return { success: true } as ISuccess
      } catch (err) {
        return getError(err)
      }
    },

    async fetchMerchantCreditors(marketplaceId: number): Promise<IOptions[] | IError> {
      try {
        const { data } = await client.get(`marketplaces/v1/${marketplaceId}/merchants-creditors`)

        return data.items as IOptions[]
      } catch (err) {
        return getError(err)
      }
    },

    async fetchMarketplacesByMerchantId(merchantId: number, page: number, perPage: number): Promise<any | IError> {
      try {
        const url = `v1/marketplaces?per_page=${perPage}&page=${page}&merchant=${merchantId}`
        const { data } = await client.get(url)

        return data
      } catch (err) {
        return getError(err)
      }
    },
  }
}
