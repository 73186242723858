/* eslint-disable eqeqeq */
const es = {
  languageData: {
    plurals: function (n, ord) {
      if (ord) return 'other'
      return n == 1 ? 'one' : 'other'
    },
  },
  messages: {
    '(demais parcelas)': '(otras cuotas)',
    '(dias de repasse da 1\xB0 parcela)': '(d\xEDas de transferencia de la 1ra entrega)',
    '- N\xE3o informado': '- No informado',
    'A receber': 'A recibir',
    'A senha deve conter no m\xEDnimo 6 (seis) caracteres':
      'La contrase\xF1a debe contener al menos 6 (seis) caracteres',
    'Acesso Restrito': 'Acceso restringido',
    'Período de recebimento': 'Período de recibo',
    Afastamento: 'Despeje',
    Agendado: 'Programado',
    Agência: 'Agencia',
    Analisando: 'Analizando',
    'Antecipa\xE7\xE3o de Receb\xEDveis': 'Pago anticipado de cuentas por cobrar',
    'Ao vincular um Marketplace, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'Al vincular un Marketplace, esta cuenta no se puede cambiar en el futuro.',
    'Ao vincular um Seller, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'Al vincular un Seller, esta cuenta no se puede cambiar en el futuro.',
    Aprovado: 'Aprobado',
    'As senhas devem ser iguais': 'Las contrase\xF1as deben ser las mismas',
    Ativo: 'Activo',
    'Autorization ID': 'Autorization ID',
    Bairro: 'Barrio',
    Banco: 'Banco',
    'Banco Cedente': 'Banco Transferidor',
    'Bloqueado + Pendente': 'Bloqueado + Pendiente',
    CEP: 'C\xF3digo postal',
    CNES: 'CNES',
    CNPJ: 'CNPJ',
    CRM: 'CRM',
    'Campos adicionais': 'Campos adicionales',
    Cancelado: 'Cancelado',
    'Carregando...': 'Cargando...',
    Carteira: 'Billetera',
    Cedente: 'Transferidor',
    Cedentes: 'Transferidores',
    'Chave Maxipago': 'Llave Maxipago',
    Cidade: 'Ciudad',
    'Como os clientes v\xE3o comprar nesta loja?': '\xBFC\xF3mo comprar\xE1n los clientes en esta tienda?',
    Complemento: 'Complemento',
    Conta: 'Cuenta',
    'Conta Corrente': 'Cuenta corriente',
    'Conta Deposito': 'Cuenta de dep\xF3sito',
    'Conta Pagamento': 'Cuenta de pago',
    'Conta Poupan\xE7a': 'Cuenta de ahorros',
    'Conta corrente': 'Cuenta corriente',
    Contato: 'Contacto',
    Convênio: 'Acuerdo',
    Creditor: 'Creditor',
    Credores: 'Acreedores',
    'Criar novo creditor': 'Criar novo creditor',
    'C\xF3digo postal inv\xE1lido': 'C\xF3digo postal inv\xE1lido',
    DV: 'DV',
    'Dados de antecipa\xE7\xE3o': 'Configuraci\xf3n de anticipaci\xf3n',
    'Dados enviados com sucesso.': 'Datos enviados con \xE9xito.',
    'Dados salvos com sucesso.': 'Datos guardados exitosamente.',
    'Data de origem': 'Data de origem',
    'Data de início': 'Fecha de inicio',
    'Data de recebimento': 'Data de recebimento',
    'Data de vencimento': 'Data de vencimento',
    Deletar: 'Eliminar',
    'Desc.': 'Descripci\xF3n',
    'Dia de recebimento': 'D\xeda del mes para recibir',
    'Dia do recebimento': 'D\xEDa de recepci\xF3n',
    daysForRepaymentOfTheFirstInstallment: 'D\xEDas de transferencia de la primera cuota',
    transferDays: 'D\xEDas de transferencia (otras cuotas)',
    'Dias de repasse da primeira parcela': 'D\xEDas de transferencia de la primera cuota',
    'Digite o nome do creditor': 'Ingrese el nombre del acreedor',
    'Digite seu convenio': 'DV',
    'Digite sua ag\xEAncia': 'Ingrese su agencia',
    'Digite sua conta corrente': 'Ingrese su cuenta corriente',
    'Digite uma senha com pelo menos 6 caracteres': 'Ingrese una contrase\xF1a con al menos 6 caracteres',
    'Digito verificador': 'D\xEDgito de verificaci\xF3n',
    Diário: 'Diario',
    'E-mail': 'E-mail',
    'E-mail inv\xE1lido': 'E-mail inv\xE1lido',
    'E-mail inv\xE1lido.': 'E-mail inv\xE1lido.',
    Ecommerce: 'Ecommerce',
    Endereço: 'Direcci\xF3n',
    'Erro inesperado. Por favor tente mais tarde.': 'Error inesperado. Por favor, int\xE9ntelo de nuevo m\xE1s tarde',
    'Erro!': '\xA1Error!',
    'Escolha a \xE1rea de neg\xF3cio.': 'Elige el \xE1rea de negocios.',
    'Escolha o Gateway': 'Elegir la Gateway',
    'Escolha o cedente': 'Elija el transferidor',
    'Escolha o tipo de programa.': 'Elige el tipo de programa.',
    'Escolha um banco': 'Elige un banco',
    'Escolha um banco cedente': 'Elige un banco transferidor',
    'Escolha um banco cedente.': 'Elige un banco transferidor.',
    'Escolha um credor': 'Elige un prestamista',
    'Escolha um nome para o novo cedente.': 'Elija un nombre para el nuevo transferidor.',
    Espécie: 'Especie',
    'Esp\xE9cie Documento': 'Documento de especies',
    'Essa transa\xE7\xE3o nao possui detalhes': 'Esta transacci\xF3n no tiene detalles.',
    'Esta conta est\xE1 vinculada \xE0 um {0} e n\xE3o pode ser alterada.': function (a) {
      return ['Esta cuenta est\xE1 vinculada a un ', a('0'), ' y no se puede cambiar.']
    },
    Estabelecimentos: 'Establecimientos',
    Estado: 'Estado',
    'Exibir apenas estabelecimentos vinculados': 'Filtrar a los establecimientos vinculados',
    'Falha na socicita\xE7\xE3o. Por favor, tente novamente.': 'La socitaci\xF3n fall\xF3. Int\xE9ntalo de nuevo.',
    'Falha no envio dos dados. Por favor, tente novamente mais tarde':
      'Error al enviar datos. Por favor, int\xE9ntelo de nuevo m\xE1s tarde',
    'Falha!': '\xA1Fracaso!',
    Fechar: 'Cerca',
    Filtrar: 'Filtrar',
    mdrTransferForm: 'Formulario de transferencia MDR',
    transferFormOfTheTransaction: 'Formulario de transferencia de transacciones',
    'Forma de repasse do MDR': 'Formulario de transferencia MDR',
    'Formato n\xE3o suportado.': 'Formato no compatible.',
    'ID da conta': 'ID de la cuenta',
    'ID de autoriza\xE7\xE3o': 'ID de autorizaci\xF3n',
    Id: 'Id',
    'Id Maxipago': 'Id Maxipago',
    'Id da conta': 'ID de la cuenta',
    'Informe o bairro.': 'Informar al barrio.',
    'Informe o endere\xE7o.': 'Ingrese la direcci\xF3n.',
    'Informe o nome da empresa.': 'Ingrese el nombre de la compa\xF1\xEDa.',
    'Informe o n\xFAmero.': 'Ingrese el n\xFAmero.',
    'Inscri\xE7\xE3o Municipal': 'Registro Municipal',
    'Insira seu nome.': 'Introduzca su nombre.',
    'Insira um nome': 'Ingresa un nombre',
    Instruções: 'Instrucciones',
    Integral: 'Integral',
    'Item deletado com sucesso.': 'Item successfully deleted.',
    Liquidado: 'Colocado',
    Local: 'Ubicaci\xF3n',
    Logradouro: 'Lugar p\xFAblico',
    MDR: 'MDR',
    Marketplace: 'Marketplace',
    Mensal: 'Mensual',
    Moeda: 'Moneda',
    'M\xE9todo de cr\xE9dito': 'M\xE9todo de cr\xE9dito',
    'Nenhum item encontrado.': 'No se encontraron art\xEDculos.',
    'Nenhum resultado encontrado. Tente fazer outra pesquisa.':
      'Ning\xFAn resultado encontrado. Intenta otra b\xFAsqueda.',
    Nome: 'Nombre',
    'Nome Fantasia': 'Nombre de fantas\xEDa',
    'Nome da Empresa': 'Nombre de la empresa',
    operationName: 'Nombre de la operaci\xF3n',
    'Nome da marca': 'Nome da marca',
    'Nome do Marketplace': 'Nombre del Marketplace',
    'Nome do contato': 'Nome do contato',
    creditorName: 'Nombre del acreedor',
    establishmentName: 'Nombre del establecimiento',
    'Nome do m\xE9dico': 'El nombre del doctor',
    'Nome do novo cedente': 'Nombre del nuevo transferidor',
    'Novo Cedente': 'Nuevo Transferidor',
    'Novo Credor': 'Nuevo prestamista',
    'Novo Marketplace': 'Nuevo Mercado',
    'Novo cedente': 'Nuevo transferidor',
    'Novo credor': 'Nuevo credor',
    'Novo estabelecimento': 'Nuevo establecimiento',
    documentNumber: 'Numero do documento',
    'N\xE3o Existem transa\xE7\xF5es para a data iniciada em: {initialDateText}': function (a) {
      return ['No hay transacciones para la fecha que comienza en: ', a('initialDateText')]
    },
    'N\xE3o Existem transa\xE7\xF5es para o perido entre: {initialDateText} - {endDateText}': function (a) {
      return [
        'No hay transacciones para el per\xEDodo comprendido entre: ',
        a('initialDateText'),
        ' - ',
        a('endDateText'),
      ]
    },
    'N\xE3o informado': 'No informado',
    'N\xE3o possui taxas e tarifas cadastradas': 'Sin tarifas y tarifas registradas',
    Número: 'Numero',
    'N\xFAmero de documento inv\xE1lido.': 'N\xFAmero de documento no v\xE1lido',
    'N\xFAmero de telefone inv\xE1lido.': 'Numero de telefono invalido.',
    'N\xFAmero do documento': 'N\xFAmero del Documento',
    'N\xFAmero do terminal POS': 'N\xFAmero de terminal POS',
    'O c\xF3digo do seu estabelecimento na plataforma PISMO \xE9:':
      'El c\xF3digo de su establecimiento en la plataforma PISMO es:',
    Observações: 'Comentarios',
    'Ocorreu um erro no servidor. Tente novamente em alguns instantes.':
      'Se ha producido un error en el servidor. Int\xE9ntalo de nuevo en un momento.',
    Operação: 'Operaci\xF3n',
    operations: 'Operaciones',
    'Ou para o modelo de liquida\xE7\xE3o CNAB, por favor selecione um creditor existente':
      'Ou para o modelo de liquida\xE7\xE3o CNAB, por favor selecione um creditor existente',
    'Ou para o modelo de liquida\xE7\xE3o digital, por favor crie um novo creditor':
      'Ou para o modelo de liquida\xE7\xE3o digital, por favor crie um novo creditor',
    PDV: 'PDV',
    'PORCENTAGEM RAV': 'PORCENTAJE RAV',
    POS: 'POS',
    'Pagamento obrigat\xF3rio? (Aceite)': '\xBFSe requiere pago? (Aceptado)',
    Pago: 'Pagado',
    installments: 'Entrega',
    Parcelas: 'Parcelas',
    País: 'Pa\xEDs',
    Pendente: 'Pendiente',
    allowCardlessTransactions: 'Permitir transacciones sin tarjeta',
    allowTransactionsOnlyWithDoc: 'Permitir transacciones utilizando solo CPF / CNPJ',
    'Periodicidade de Liquidação': 'Frecuencia de liquidaci\xf3n',
    'Pesquise por usu\xE1rio (e-mail)': 'B\xFAsqueda por usuario (e-mail)',
    'Pesquise um Marketplace': 'Buscar un mercado',
    'Pesquise um cedente': 'Buscar un transferidor',
    'Pesquise um credor': 'Busque un prestamista',
    'Pesquise um estabelecimento': 'Buscar un establecimiento',
    'Por favor selecione uma data': 'Por favor selecione uma data',
    'Por favor, Digite a chave Maxipago': 'Introduzca la clave Maxipago',
    'Por favor, Digite a cidade': 'Por favor ingrese la ciudad',
    'Por favor, Digite a inscri\xE7\xE3o municipal': 'Por favor ingrese el registro municipal',
    'Por favor, Digite o DDD telefone': 'Ingrese el n\xFAmero de tel\xE9fono',
    'Por favor, Digite o estado': 'Por favor ingrese el estado',
    'Por favor, Digite o id': 'Por favor ingrese la identificaci\xF3n',
    'Por favor, Digite o nome do seu bairro': 'Ingrese el nombre de su vecindario',
    'Por favor, Digite o nome fantasia': 'Ingrese su nombre elegante',
    'Por favor, Digite o nome raz\xE3o social': 'Por favor ingrese el nombre',
    'Por favor, Digite o numero do Logradouro': 'Ingrese el n\xFAmero de la calle',
    'Por favor, Digite o pa\xEDs': 'Ingrese el pa\xEDs',
    'Por favor, Digite seu cep': 'Por favor ingrese su c\xF3digo postal',
    'Por favor, Digite seu endere\xE7o': 'Por favor ingrese su direccion',
    'Por favor, Digite seu numero de documento': 'Ingrese su n\xFAmero de documento',
    'Por favor, Digite um nome contato': 'Ingrese un nombre de contacto',
    'Por favor, Digite um numero correspondente a dias de repasse a demais parcelas':
      'Por favor, ingrese un n\xFAmero correspondiente a los d\xEDas de transferencia a otras cuotas',
    'Por favor, Digite um numero correspondente a dias de repasse da primeira parcela':
      'Por favor, ingrese un n\xFAmero correspondiente a los d\xEDas de transferencia de la primera cuota',
    selectAnOption: 'Por favor seleccione una opci\xF3n',
    'Por favor, digite o numero': 'Por favor ingrese el numero',
    'Por favor, digite o numero da agencia': 'Por favor Ingrese el n\xFAmero de agencia',
    'Por favor, digite o numero da conta': 'Por favor Ingrese su n\xFAmero de cuenta',
    'Por favor, preencha o campo nome': 'Por favor complete el campo de nombre',
    'Por favor, selecione o tipo da conta.': 'Seleccione su tipo de cuenta.',
    'Por favor, selecione um banco': 'Por favor seleccione un banco',
    'Por favor, selecione um banco cedente': 'Seleccione un banco transferidor',
    'Por favor, selecione uma op\xE7\xE3o': 'Por favor seleccione una opci\xF3n',
    'Porcentagem M\xE1x de antecipa\xE7\xE3o': 'Porcentaje m\xE1ximo de anticipaci\xF3n',
    'Porcentagem m\xE1x. de antecipa\xE7\xE3o': 'Porcentaje m\xE1x. anticipaci\xF3n',
    Quantidade: 'Cantidad',
    'Quarta-feira': 'Mi\xE9rcoles',
    'Quinta-feira': 'Jueves',
    Quinzenal: 'Quincenal',
    'Raz\xE3o Social': 'Raz\xF3n social',
    Rejeitado: 'Rechazado',
    Repasse: 'Transferir',
    'Repasse MDR': 'Transferencia MDR',
    'Repasse da transa\xE7\xE3o': 'Transferencia de transacciones',
    'Repita a senha': 'repite la contrase\xF1a',
    'Requisi\xE7\xE3o n\xE3o efetuada. Por favor tente mais tarde':
      'Solicitud no realizada. Por favor, int\xE9ntelo de nuevo m\xE1s tarde',
    Salvar: 'Guardar',
    'Segunda-feira': 'Lunes',
    'Selecionar um creditor existente': 'Selecionar um creditor existente',
    'Selecione a data': 'Selecione a data',
    'Selecione a opera\xE7\xE3o': 'Selecione a opera\xE7\xE3o',
    'Selecione ao menos uma regra:': 'Seleccione al menos una regla:',
    'Selecione o Status': 'Selecione o Status',
    'Selecione um Marketplace': 'Seleccione un Marketplace',
    'Selecione um Seller': 'Seleccione un Seller',
    Semanal: 'Semanal',
    successfullyRegisteredData: 'Sus datos han sido registrados con \xE9xito.',
    'Seus dados foram salvos com sucesso.': 'Tus datos se han guardado correctamente.',
    'Sexta-feira': 'Viernes',
    'Status alterado com sucesso.': 'Status cambi\xF3 con \xE9xito.',
    'Status do processo': 'Status del proceso',
    'Status registrado.': 'Estado registrado.',
    'Suas altera\xE7\xF5es foram registradas com sucesso.': 'Sus cambios se han registrado correctamente.',
    'Sucesso!': '\xC9xito!',
    'TARIFA RAV': 'TASA RAV',
    'TAXA RAV': 'TASA RAV',
    'Tarifa de Antecipa\xE7\xE3o': 'Tasa de anticipaci\xF3n (cantidad fija)',
    'Tarifa de antecipa\xE7\xE3o': 'Tasa de anticipaci\xF3n',
    'Tarifa por transa\xE7\xE3o': 'Tarifa de transacci\xF3n',
    'Taxa de desconto': 'Tasa de descuento (porcentaje)',
    transitionFee: 'Tarifa de transacci\xF3n',
    'Taxa de transi\xE7\xE3o': 'Tasa de transici\xF3n',
    'Taxas e tarifas relacionadas ao marketplace n\xE3o foram encontradas.':
      'No se encontraron tarifas y cargos relacionados con el mercado.',
    Telefone: 'Tel\xE9fono',
    'Telefone Adicional (opcional)': 'Tel\xE9fono adicional (opcional)',
    'Telefone II (opcional)': 'Tel\xE9fono II (opcional)',
    'Telefone principal': 'Tel\xE9fono Principal',
    'Tem certeza que deseja alterar o status?': '\xBFEst\xE1 seguro de que desea cambiar el status?',
    'Tem certeza que deseja remover este item?': '\xBFEst\xE1s seguro de que deseas eliminar este art\xEDculo?',
    'Ter\xE7a-feira': 'Martesv',
    'Tipo de conta': 'Tipo de cuenta',
    'Tipo de opera\xE7\xE3o': 'Tipo de operaci\xF3n',
    'Todos os estabelecimentos': 'Todos los establecimientos',
    'Total a pagar': 'Total a pagar',
    'Tipo da Conta para Liquidação': 'Tipo de cuenta para liquidaci\xf3n',
    'Use este n\xFAnero para as configura\xE7\xF5es do seu POS ou PDV.':
      'Use este n\xFAmero para su POS o configuraci\xF3n de PDV.',
    Usuários: 'Usuarios',
    'Vence em': 'Gana en',
    'Vincular conta a um Marketplace': 'Vincular cuenta a un Marketplace',
    'Vincular conta a um Seller': 'Vincular cuenta a un Seller',
    'Voc\xEA n\xE3o tem permiss\xE3o para acessar esta \xE1rea.': 'No se le permite acceder a esta \xE1rea.',
    Webpos: 'Webpos',
    'Workflow status': 'Workflow status',
    'a pagar': 'para pagar',
    agenda: 'horario para recibir',
    'agenda a receber': 'horario para recibir',
    'autoriza\xE7\xE3o: {0}': function (a) {
      return ['autorizaci\xF3n: ', a('0')]
    },
    bruto: '\xE1spero',
    cancelar: 'cancelar',
    condições: 'condiciones',
    confirmar: 'confirmar',
    confirmação: 'confirmaci\xF3n',
    continuar: 'seguir',
    'c\xF3digo postal': 'C\xF3digo postal',
    data: 'fecha',
    'data do recebimento': 'Fecha de entrega',
    'data final': 'fecha final',
    'data inicial': 'la fecha de inicio',
    'data origem': 'fecha de origen',
    'data recebimento': 'fecha de recepci\xF3n',
    'data vencimento': 'fecha de vencimiento',
    deletar: 'eliminar',
    desconto: 'descuento',
    'desconto antecipa\xE7\xE3o': 'descuento anticipado',
    'desconto taxa': 'desconto taxa',
    desvincular: 'desconectar',
    download: 'download',
    'e-mail': 'e-mail',
    'elo cr\xE9dito': 'elo cr\xE9dito',
    'elo d\xE9bito': 'elo d\xE9bito',
    enviar: 'enviar',
    estabelecimentos: 'establecimientos',
    'hiper cr\xE9dito': 'hiper cr\xE9dito',
    'hiper d\xE9bito': 'hiper d\xE9bito',
    home: 'home',
    'id de autoriza\xE7\xE3o': 'id de autorizaci\xF3n',
    incluir: 'incluir',
    líquido: 'l\xEDquido',
    moeda: 'moneda',
    nome: 'nombre',
    novo: 'nuevo',
    'n\xFAmero do documento': 'N\xFAmero del documento',
    observações: 'comentarios',
    operação: 'operaci\xF3n',
    outros: 'outros',
    país: 'pa\xEDs',
    próximo: 'siguiente',
    voltar: 'regresar',
    receita: 'ingresos',
    remover: 'eliminar',
    salvar: 'salvar',
    senha: 'contrase\xF1a',
    status: 'estado',
    'status do processo': 'status del proceso',
    'taxas / tarifas': 'honorarios / tarifas',
    antecipacao: 'Anticipación',
    liquidoAReceber: 'Cuenta por cobrar neta',
    tipo: 'tipo',
    'tipo do programa': 'Programa',
    transações: 'actas',
    'valor bruto': 'valor bruto',
    'valor l\xEDquido': 'valor neto',
    'valor recebido': 'Cantidad recibida',
    vincular: 'enlace',
    'N\xdamero do documento de identifica\xE7\xE3o do estabelecimento comercial':
      'N\xdamero de documento de identificaci\xf3n del establecimiento comercial',
    'Programa do tipo merchant (criado previamente)': 'Programa tipo comerciante (previamente creado)',
    '\xc1rea de neg\xf3cio conforme tabela previamente cadastrada':
      '\xc1rea de negocio según tabla previamente registrada',
    'Nome do Estabelecimento': 'Nombre del Establecimiento',
    'O tipo de conta pode ser interno ou externa':
      'El tipo de cuenta puede ser interna (Cuenta plataforma Pismo) o externa (Cuenta Bancaria Externa)',
    'Periodicidade de liquida\xE7\xE3o que pode ser configurada de di\xc1ria a mensal':
      'Frecuencia de liquidaci\xf3n configurable de diaria a mensual',
    'A taxa de antecipação pode ser Fixa, Percentual ou a combinação de ambos.':
      'La tasa de anticipo puede ser Fija, Porcentual o una combinaci\xf3n de ambas.',
    'A taxa de desconto pode ser Fixa, Percentual ou a combinação de ambos.':
      'La tasa de descuento puede ser Fija, Porcentual o una combinaci\xf3n de ambas.',
    'Porcentagem m\xc1xima que pode ser antecipada pelo estabelecimento.':
      'Porcentaje máximo que puede anticipar el establecimiento.',
    '{0,date,date0}': function (a) {
      return [a('0', 'date', 'date0')]
    },
    '{0,number,number0}': function (a) {
      return [a('0', 'number', 'number0')]
    },
    '{item,number,number0}': function (a) {
      return [a('item', 'number', 'number0')]
    },
    '{res}': function (a) {
      return [a('res')]
    },
    '\xC1rea de neg\xF3cio': '\xC1rea de negocio',
    Detalhes: 'Detalles',
    sair: 'Cerrar sesión',
    TotalARreceber: 'Cuentas por cobrar totales',
    ValorAAntecipar: 'Cantidad a anticipar',
    LiquidoAReceber: 'Cuentas por cobrar netas totales',
    SolicitarAntecipacao: 'Solicitar anticipo',
    AntecipacaoRealizadaComSucesso: 'Anticipación realizada con éxito',
    NaoExistemAntecipacoesRalizaveis: 'No hay anticipos que hacer',
    Antecipacao: 'Anticipación',
    juros: 'honorarios',
    mes: 'mes',
    Tarifa: 'tasa',
    TaxaEfetivaCET: 'Tasa efectiva de AEC',
    ConfirmarAntecipacao: 'Confirmar anticipación',
    ValorLiquidoAReceber: 'Monto neto por cobrar',
    datasAntecipacao: 'Fechas disponibles para reservar con antelación',
    selecionePeriodoAntecipacao:
      'Seleccione el período deseado utilizando el filtro de selección de arriba, o haga clic directamente en los elementos del gráfico',
    chartDateFormat: 'dd/MM/yy',
    total: 'Total',
    selecionePeriodo: 'Seleccione el período deseado',
    transactionWithoutDetails: 'Esta transacción no tiene detalles',
    transactionsOfTheDay: 'Transacciones el',
    noTransactions: 'No hay transacciones para el día seleccionado',
    selectPeriod: 'Seleccionar periodo',
    selectSeller: 'Seleccione un Seller',
    genericApiError: 'Se ha producido un error en el servidor. Int\xE9ntalo de nuevo en un momento.',
    mustHaveAtLeastOneEstablishmentLinked: 'El mercado debe tener al menos un establecimiento vinculado al mismo.',
    selectAnEstablishmentToViewTransactions: 'Seleccione un establecimiento para ver las transacciones:',
    dataSuccessfullySaved: 'Datos guardados correctamente',
    itemDeletedSuccessfully: 'Elemento eliminado con éxito',
    accountTypeForSettlement: 'Tipo de cuenta para liquidación',
    platformAccount: 'Cuenta de plataforma',
  },
}

export default es
